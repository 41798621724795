import Vue from 'vue';
import Vuex from 'vuex';

import {
    liDehnProject
} from './modules/liDenhProject.module';


Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        liDehnProject: liDehnProject
    }
});
