import liApiService from '../services/liApi.service';
export const liDehnProject = {
    namespaced: true,
    state: {
        general: {
            language: 'NL'
        },
        project: null,
        custom:null,
        questions: {
            "00r": null,
            "01i":null,
            "02u":null
        },
        projectDialog: false,
        
    },
    actions: {
       
        GET_QUESTIONS_R({
            commit
        }){
            
            return liApiService
                .getQuestionsR()
                .then(response => {
                        commit('setQuestionsR', response);
                        return Promise.resolve();
                    },
                    error => {
                      console.log(error);
                    });
        },
        GET_QUESTIONS_U({
            commit
        }){
            
            return liApiService
                .getQuestionsU()
                .then(response => {
                        commit('setQuestionsU', response);
                        return Promise.resolve();
                    },
                    error => {
                      console.log(error);
                    });
        },
        GET_QUESTIONS_I({
            commit
        }){
            
            return liApiService
                .getQuestionsI()
                .then(response => {
                        commit('setQuestionsI', response);
                        return Promise.resolve();
                    },
                    error => {
                      console.log(error);
                    });
        },
        SET_PROJECT({
            commit
        }){
            commit('setProject');
            return Promise.resolve();
        },
        RESTORE_PROJECT({
            commit
        }, obj){
            commit('restoreProject',obj);
            return Promise.resolve();
        },
        SET_NAME({
            commit
        }, name) {
            commit('setName', name);
            return Promise.resolve();
        },
        SET_TYPE({
            commit
        }, type) {
            commit('setType', type);
            return Promise.resolve();
        },
        SET_GRID({
            commit
        }, grid) {
            commit('setGrid', grid);
            return Promise.resolve();
        },
        SET_MAIN_DISTRIBUTOR({
            commit
        }, distributor) {
            commit('setMainDistributor', distributor);
            return Promise.resolve();
        },
        REMOVE_MAIN_DISTRIBUTOR({
            commit
        }){
            commit('removeMainDistributor');
            return Promise.resolve();
        },
        SET_DIVIDER({
            commit
        }, divider) {
            commit('setDivider', divider);
            return Promise.resolve();
        },
        REMOVE_DIVIDER({
            commit
        }, index) {
            commit('removeDivider', index);
            return Promise.resolve();
        },
        SET_CRITENDGROUP({
            commit
        }, product) {
            commit('setCritEndGroup', product);
            return Promise.resolve();
        },
        REMOVE_CRIT_END_GROUP({
            commit
        }, index) {
            commit('removeCritEndGroup', index);
            return Promise.resolve();
        },
        UPDATE_LOCALSTORAGE({
            commit
        }){
            commit('updateLocalStorage');
        },
        SET_CUSTOM_VALUE({
            commit
        },obj){
            commit('setCustomValue',obj);
        },
        SET_PROJECT_DIALOG({
            commit
        },bool){
            commit('setProjectDialog',bool);
        },
        
    },
    mutations: {
        setProjectDialog(state, bool){
            state.projectDialog = bool;
        },
        setCustomValue(state,obj){
            // console.log(state,obj)
            state.custom[obj.path] = obj.value;
            localStorage.setItem('liProjectDehnCustom', JSON.stringify(state.custom))
        },
        setQuestionsR(state,questions){
            state.questions['00r'] = questions;
        },
        setQuestionsI(state,questions){
            state.questions['01i'] = questions;
        },
        setQuestionsU(state,questions){
            state.questions['02u'] = questions;
        },
        updateLocalStorage(state){
            localStorage.setItem('liProjectDehn', JSON.stringify(state.project))
        },
        setProject(state){
            state.project= {
                "name": "",
                "type":"",
                "grid": "",
                "main": {
                    "articleNo":null,
                    "name":"",
                    "alertText":""
                },
                "dividers": [],
                "critEndGroups":[]
            }
            state.custom= {};
            localStorage.setItem('liProjectDehn', JSON.stringify(state.project))
            localStorage.setItem('liProjectDehnCustom', JSON.stringify(state.custom))
            console.log('setProject');
        },
        removeMainDistributor(state){
            state.project= {
                "name": state.project.name,
                "type": state.project.type,
                "grid": state.project.grid,
                "main": {
                    "articleNo":null,
                    "name":"",
                    "alertText":""
                },
                "dividers": [],
                "critEndGroups":[]
            }
            localStorage.setItem('liProjectDehn', JSON.stringify(state.project))
        },
        restoreProject:(state, obj)=> {
            state.project = obj.project;
            state.custom = obj.custom;
            if (obj.project.type !='' && obj.project.main.articleNo !=null && obj.project.main.articleNo !=''){
                state.projectDialog = true;
            }
        },
        setType:(state, type)=>{
            state.project.type = type;
            localStorage.setItem('liProjectDehn', JSON.stringify(state.project))
        },
        setName:(state, name)=>{
            state.project.name = name;
            localStorage.setItem('liProjectDehn', JSON.stringify(state.project))
        },
        setGrid:(state, grid)=>{
            state.project.grid = grid;
            localStorage.setItem('liProjectDehn', JSON.stringify(state.project))
        },
        setMainDistributor:(state, distributor) =>{
            state.project.main.articleNo = distributor.articleNo;
            state.project.main.name = distributor.name;
            state.project.main.alertText = distributor.alertText;
            localStorage.setItem('liProjectDehn', JSON.stringify(state.project))
        },
        setDivider:(state, divider) =>{
            state.project.dividers = [
                divider, ...state.project.dividers
            ]
            localStorage.setItem('liProjectDehn', JSON.stringify(state.project))
        },
        removeDivider:(state, index) =>{
            state.project.dividers.splice(index, 1);
            localStorage.setItem('liProjectDehn', JSON.stringify(state.project))
        },
        removeCritEndGroup:(state, index) =>{
            state.project.critEndGroups.splice(index, 1);
            localStorage.setItem('liProjectDehn', JSON.stringify(state.project))
        },
        setCritEndGroup:(state, product) =>{
            state.project.critEndGroups = [
                product, ...state.project.critEndGroups
            ]
            localStorage.setItem('liProjectDehn', JSON.stringify(state.project))
        }
    },
    getters: {
        getProjectDialog:(state)=>{
            return state.projectDialog;
        },
        getProject: (state) => {
            return state.project;
        },
        getProjectName: (state) => {
            return state.project.name;
        },
        getProjectMainDistributor: (state) => {
            return state.project.main;
        },
        getProjectDivider: (state) => {
            return state.project.dividers;
        },
        getQuestionsR: (state) => {
            return state.questions['00r'];
        },
        getQuestionsI: (state) => {
            return state.questions['01i'];
        },
        getQuestionsU: (state) => {
            return state.questions['02u'];
        },
        getQuestions: (state) => {
            return state.questions;
        },

    }
};