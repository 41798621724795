import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

// PAGE TITLE 
import VuePageTitle from 'vue-page-title'

Vue.use(VuePageTitle, {
  // prefix: 'My App - ',
  suffix: '- DEHN Selectie Matrix'
});

// LINNOX MIXINS
import liMixin from './mixins/liMixin';
Vue.mixin(liMixin)

// Custom filters
import './filters/filters';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
