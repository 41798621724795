<template>
  <v-container fluid class="fill-height grey">
    <v-container>
    <v-row
      align="center"
      justify="start"
      justify-md="space-around"
      class="px-5"
    >
    
      <v-col cols="12" lg="8" class="text-center">
            <v-sheet elevation="5" class="py-15 px-5">
        <template v-if="debug">
          <p v-text="question.identifier" />
          <p v-text="question.text" />
        </template>
       
        <p
          class="mb-5"
          :class="[isMobile ? 'text-h5' : 'text-h4']"
          v-html="appConfig.texts.type.title"
        />
        <p
          class="mb-5"
          v-if="appConfig.texts.type.subtitle != ''"
          v-html="appConfig.texts.type.subtitle"
        />
        <div class="mt-15">
          <!-- {{computedProjectType}} -->
          <template v-for="(btn, n) in appConfig.texts.type.buttons">
            <v-btn
              class="mx-sm-5"
              depressed
              :color="btn.color"
              :block="isMobile"
              :key="n"
              :class="[
                project.type === btn.type ? 'darken-3' : '',
                isMobile ? 'my-1' : '',
                btn.class,
              ]"
              @click.prevent="selectType(btn)"
            >
              <template v-if="project.type === btn.type">
                <v-icon class="mr-2">mdi-check</v-icon>
                {{ btn.text }}
                
              </template>
              <template v-else>
                <v-icon v-if="btn.icon && btn.icon != ''" class="mr-2">
                  {{ btn.icon }}
                </v-icon>
                {{ btn.text }}
                
              </template>
            </v-btn>
          </template>
        </div>
            </v-sheet>
      </v-col>
    </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "Type",
  title:"",
  computed:{
    question(){
      return this.appConfig.texts.type;
    },
    questions(){
      return this.$store.getters['liDehnProject/getQuestions'];
    },
    computedProjectType() {
      if (
        this.project != null &&
        this.project.type != undefined &&
        this.project.type != ""
      ) {
        return this.project.type;
      } else {
        return false;
      }
    },
  },
  mounted() {
    if (this.question === undefined) {
      this.error = true;
    } else{
      this.setupTitle();

    }
      
  },
  methods: {
     setupTitle(){
      
      if (this.question == null){
        setTimeout(() => {
          this.setupTitle();
        }, 500);
      } else {
          this.$title = `${this.$options.filters.trimLength(this.stripTags(this.question.question),15)}`;
      }
      
      // console.log(this.question.question);
    },
    selectType(btn) {
      this.setProjectDialog(false);
      if(this.computedProjectType != btn.type){
        this.$store.dispatch("liDehnProject/SET_PROJECT").then(()=>{
          this.$store.dispatch("liDehnProject/SET_TYPE", btn.type);
          if (btn.type === "00r") {
            this.$store.dispatch("liDehnProject/SET_GRID", "-");
          }
        });
      } else {
        this.$store.dispatch("liDehnProject/SET_TYPE", btn.type);
        if (btn.type === "00r") {
          this.$store.dispatch("liDehnProject/SET_GRID", "-");
        }
      }
      
      let nexQuestion =   this.questions[btn.type].find(question => question.start == 1);
      this.$router.push('/q/'+nexQuestion.identifier);

    },
  },
};
</script>
