import liDehnConfig from "../assets/json/nl/config.json";
import { mapState } from "vuex";

export default {
  data: () => ({
    isMobile: false,
    debug: false,
  }),
  beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    let project = localStorage.getItem("liProjectDehn");
    if(typeof project == "string"){
      project = JSON.parse(project)
    }
    if(typeof project == "object" && project.type !="" && project.main.articleNo != null){
      this.gotProject = true;
      // console.log(this.$route);
      // console.log('got project, want to view?', this.$route.name);          
    } 
  },
  computed: {
    ...mapState("liDehnProject", ["general", "project"]),
    appConfig() {
      return liDehnConfig;
    }
  },

  methods: {
    stripTags(value) {
      if (!value) return "";
      value = value.toString();
      // value - eturn value.replace("<br>", " ");
      return value.replace(/(<([^>]+)>)/gi, " ");
    },
    openUrl(url){
      window.open(url,'_blank');
    },
    setProjectDialog(bool){
      console.log('setProjectDialog',bool);
      this.$store.dispatch("liDehnProject/SET_PROJECT_DIALOG",bool);
    },
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
    addCustomToStore(toValue){
      const VALUE_LOCATOR = toValue.indexOf("=");
      const VALUE_TO_SET = toValue.substring(0, VALUE_LOCATOR);
      const ACTUAL_VALUE = toValue.substring(VALUE_LOCATOR+1, toValue.length );
      this.$store.dispatch("liDehnProject/SET_CUSTOM_VALUE", {
        path: VALUE_TO_SET,
        value: ACTUAL_VALUE,
      });
    },
    getCustomFromStore(path){
      console.log(this.$store.getters["liDehnProject/getQuestions"](path));
    }
    
  },
};
