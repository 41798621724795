<template>
  <v-container class="px-0 py-0" fluid style="min-height: 100vh;">
    <v-img :src="appConfig.images.homeBackground" height="100vh" class="primary darken-4">
      <v-container fluid style="position:absolute; bottom:20vh;">
        <v-row align="center" justify="space-around" class="px-5  li-text-shadow">
          <h1 class="text-h1 white--text mb-5 text-center" v-html="appConfig.texts.home.title" />
        </v-row>
        <v-row align="center" justify="space-around" class="px-5 li-text-shadow">
          <p class="white--text mb-10 text-center" v-html="appConfig.texts.home.subtitle" />
        </v-row>
        <v-row align="center" justify="space-around">
          <template v-for="(btn,n) in appConfig.texts.home.buttons">
            <v-btn :to="btn.to" :color="btn.color" :class="btn.class" :key="n" class="px-10 bg-white" x-large>
              <h3 v-text="btn.text" />
            </v-btn>
          </template>
        </v-row>
      </v-container>
    </v-img>
  </v-container>
</template>

<script>
export default {
  name: "Home",
};
</script>
