import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Type from "../views/Type.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/type",
    name: "Type",
    component: Type,
  },
  {
    path: "/q/:identifier",
    name: "Question",
    component: () =>
      import(/* webpackChunkName: "Question" */ "../views/Question.vue"),
  },
  {
    path: "/ie/:identifier",
    name: "ifelse",
    component: () =>
      import(/* webpackChunkName: "Question" */ "../views/IfElse.vue"),
  },
  {
    path: "/g/:identifier",
    name: "Grid",
    component: () =>
      import(/* webpackChunkName: "Question" */ "../views/Grid.vue"),
  },
  {
    path: "/md/:identifier",
    name: "MainDistributor",
    component: () =>
      import(/* webpackChunkName: "Question" */ "../views/MainDistributor.vue"),
  },
  {
    path: "/sd/:identifier",
    name: "SubDivider",
    component: () =>
      import(/* webpackChunkName: "Question" */ "../views/SubDivider.vue"),
  },
  {
    path: "/sdr/:identifier",
    name: "SubDividerResidential",
    component: () =>
      import(
        /* webpackChunkName: "Question" */ "../views/SubDividerResidential.vue"
      ),
  },
  {
    path: "/ce/:identifier",
    name: "CriticalEndGroup",
    component: () =>
      import(
        /* webpackChunkName: "Question" */ "../views/CriticalEndGroup.vue"
      ),
  },
  {
    path: "/result",
    name: "Result",
    component: () =>
      import(/* webpackChunkName: "Question" */ "../views/Result.vue"),
  },
  {
    path: "*",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "Question" */ "../views/PageNotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    store.getters["liDehnProject/getProject"] === null &&
    !localStorage.getItem("liProjectDehn") &&
    !localStorage.getItem("liProjectDehnCustom")
  ) {
    store.dispatch("liDehnProject/SET_PROJECT");
    next({ name: "Home" });
  } else if (store.getters["liDehnProject/getProject"] === null) {
    store.dispatch("liDehnProject/RESTORE_PROJECT", {
      project: JSON.parse(localStorage.getItem("liProjectDehn")),
      custom: JSON.parse(localStorage.getItem("liProjectDehnCustom")),
    });
  
  }
  next();
});

export default router;
