<template>
  <v-app>
    <v-app-bar fixed dark elevation="0" color="primary">
      <!-- 
      {{hasProject()}}
      {{computedProjectType}}
      {{computedProjectGrid}}
      {{project}}
      -->
      <v-toolbar-title>
        <!-- <v-btn
          plain
          small
          style="text-transform: unset"
          @click.prevent="triggerHome"
        >
          <v-icon small> mdi-home</v-icon>
        </v-btn>
         -->
        <v-btn
          text
          raised
          elevation="0"
          class="li-dehn-logo"
          @click.prevent="triggerHome"
        >
          <!-- <svg
          class="dehnLogo"
          xmlns="http://www.w3.org/2000/svg"
          width="150"
          height="135"
          viewBox="0 0 150 135"
        >
          <g fill="none" fill-rule="evenodd">
            <path fill="#FFF" d="M0 0v135h150V0H0z"></path>
            <path
              fill="#E3000B"
              d="M21 83.26l108-26.285v36.74L21 120V83.26zm42.392 15.35l7.827-1.894.593-3.125-4.018.978.523-2.787 4.02-.97.603-3.11-4.02.97.54-2.794 4.02-.977.61-3.168-7.815 1.875L63.39 98.61zm10.238-2.495l3.804-.9 1.13-5.9 2.558-.615-1.11 5.89 3.824-.923 2.88-15-3.83.923-1.15 6-2.576.61 1.167-5.99-3.832.927-2.865 14.978zm12.752-3.073l3.824-.913 1.403-7.363 1.142 6.74 3.846-.925 2.888-15.02-3.85.937-1.385 7.26-1.18-6.63-3.803.914L86.38 93.04zm11.067 5.732L126.8 83.32l-27.645 6.64-1.706 8.814zm-44.82-20.24L23.277 93.988l27.667-6.695 1.685-8.762zm-2.03 23.172l5.352-1.303c3.41-.818 4.84-1.288 5.304-3.55l1.71-8.922c.31-1.496-1.083-3.275-3.632-2.645l-5.837 1.41-2.897 15.012z"
            ></path>
            <path
              fill="#E30613"
              d="M56.72 88.98l1.47-.376c.86-.22.957.438.8 1.22l-1.27 6.566c-.13.67-.595.723-1.305.888l-1.35.335 1.656-8.636z"
            ></path>
            <path
              stroke="#1D1D1B"
              stroke-width=".6"
              d="M15 0v120M135 0v120"
            ></path>
          </g>
        </svg> -->
          <svg
            version="1.1"
            id="logo"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 125 50"
           class="dehnLogo"
          >
            <rect fill="none" width="125" height="50" />
            <g>
              <polygon fill="#ffffff" points="84.8,35.5 86.5,26.7 112.5,20.8 	" />
              <polygon fill="#ffffff" points="40.7,14.5 39,23.3 12.5,29.4 	" />
              <polygon
                fill="#ffffff"
                points="51.2,34.6 58.8,32.8 59.4,29.9 55.6,30.8 56.2,27.9 59.9,27 60.5,24.1 56.7,25 57.3,22.1 61.1,21.2 61.6,18.3 54.1,20.1"
              />
              <polygon
                fill="#ffffff"
                points="61.3,32.3 65.1,31.4 66.3,25.6 68.8,25 67.7,30.8 71.4,29.9 74.3,15.4 70.5,16.3 69.4,22.1 66.8,22.7 68,16.9 64.2,17.7"
              />
              <polygon
                fill="#ffffff"
                points="74,29.3 77.8,28.5 79.1,21.3 80.3,27.9 84.1,27 86.9,12.5 83.1,13.4 81.7,20.4 80.6,14 76.8,14.8"
              />
              <g>
                <path
                  fill="#ffffff"
                  d="M46.9,25.7c0.2-1-0.2-1.2-1-1L44.7,25L43,33.7l1.3-0.3c0.8-0.2,1.3-0.3,1.5-1.3L46.9,25.7z M47.3,21.6 c2.5-0.6,3.8,1,3.5,2.9L49.1,33c-0.4,2.2-1.7,2.5-5.1,3.3l-5.5,1.3L41.4,23L47.3,21.6z"
                />
              </g>
            </g>
          </svg>
        </v-btn>
      </v-toolbar-title>
      <v-spacer> </v-spacer>
      <v-btn
        rounded
        pill
        elevation="0"
        color="white"
        class="selection text--primary mr-lg-5"
        style="text-transform: unset"
        to="/result"
        @click="setProjectDialog(false)"
        :disabled="
          hasProject &&
          (computedProjectType == false || computedProjectGrid == false)
        "
      >
        {{ appConfig.texts.app.project }}
      </v-btn>
    </v-app-bar>
    <v-main>
      <transition name="fade" mode="out-in">
        <router-view :key="$route.name + $route.params.identifier" />
      </transition>
    </v-main>
    <!-- project available dialog -->
    <template
      v-if="$route.name != null && $route.name != '' && $route.name != 'Result'"
    >
      <v-dialog v-model="projectDialog" :width="isMobile ? '350' : '500'">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            {{ appConfig.texts.app.projectDialog.title }}
          </v-card-title>

          <v-card-text>
            <p
              class="mt-3"
              v-html="appConfig.texts.app.projectDialog.description"
            />
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="">
            <div class="d-block w-100 text-center">
              <v-btn
                class="mx-sm-2"
                depressed
                text
                color="primary"
                :block="isMobile"
                @click="resetProject"
              >
                <v-icon small class="mr-2"> mdi-refresh </v-icon>
                {{ appConfig.texts.app.projectDialog.reset }}
              </v-btn>
              <!-- <v-btn
                class="mx-sm-3"
                depressed
                text
                color="dark"
                :block="isMobile"
                @click="viewProject"
              >
                {{ appConfig.texts.app.projectDialog.result }}
              </v-btn> -->
              <v-btn
                class="mx-sm-2"
                depressed
                text
                color="primary"
                :block="isMobile"
                @click="continueProject"
              >
                {{ appConfig.texts.app.projectDialog.view }}
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <v-footer
      padless
      fixed
      :class="$route.name == 'Home' ? 'px-5 pb-5' : 'px-3 pb-3'"
      style="background: transparent"
    >
      <div class="white--text" style="font-size: 75%">
        {{ new Date().getFullYear() }} {{ appConfig.footer.text }} |
        <a
          :href="appConfig.footer.legal.url"
          target="_blank"
          class="white--text"
        >
          {{ appConfig.footer.legal.name }}
        </a>
      </div>
    </v-footer>

    <!-- QUESTIONS -->
    <v-tooltip top v-if="$route.name != 'Home' && questionsDialog == false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          dark
          color="white"
          v-bind="attrs"
          v-on="on"
          class="btn-questions"
          style="bottom: 0; right: 0; position: fixed; margin: 25px"
          @click.prevent="questionsDialog = true"
        >
          <v-icon dark class="primary--text">
            {{ appConfig.texts.app.questions.icon }}
          </v-icon>
        </v-btn>
      </template>
      <span>{{ appConfig.texts.app.questions.tooltip }}</span>
    </v-tooltip>

    <v-dialog v-model="questionsDialog" :width="isMobile ? '350' : '500'">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ appConfig.texts.app.questions.title }}
        </v-card-title>

        <v-card-text>
          <p
            class="mt-3 text-center"
            v-html="appConfig.texts.app.questions.description"
          />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="">
          <div class="d-block w-100 text-center">
            <v-btn
              class="mx-sm-2"
              depressed
              text
              color="primary"
              :block="isMobile"
              @click="questionsDialog = false"
            >
              {{ appConfig.texts.app.questions.btn }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- QUESTIONS -->
  </v-app>
</template>
<script>
export default {
  name: "DEHNapp",
  data: () => ({
    questionsDialog: false,
  }),
  watch: {
    $route(to) {
      // react to route changes...
      // console.log("from", from.name);
      // console.log("to", to.name);
      if (to.name == "Home") {
        this.setProjectDialog(true);
      }
    },
  },
  mounted() {
    if (this.$store.getters["liDehnProject/getQuestionsR"] === null) {
      this.$store.dispatch("liDehnProject/GET_QUESTIONS_R");
    }
    if (this.$store.getters["liDehnProject/getQuestionsI"] === null) {
      this.$store.dispatch("liDehnProject/GET_QUESTIONS_I");
    }
    if (this.$store.getters["liDehnProject/getQuestionsU"] === null) {
      this.$store.dispatch("liDehnProject/GET_QUESTIONS_U");
    }
    window.addEventListener("popstate", () => {
      if (document.location.href.includes("/ie/")) {
        history.go(-1);
      }
    });
  },
  methods: {
    resetProject() {
      this.$store.dispatch("liDehnProject/SET_PROJECT");
      this.setProjectDialog(false);
      this.$router.push("/");
    },
    viewProject() {
      this.setProjectDialog(false);
      this.$router.push("/result");
    },
    continueProject() {
      this.setProjectDialog(false);
    },
    hasHistory() {
      return window.history.length > 2;
    },
    hasProject() {
      if (
        this.project != null &&
        this.project.type != undefined &&
        this.project.type != ""
      ) {
        return false;
      } else {
        return true;
      }
    },
    triggerHome() {
      this.setProjectDialog(true);
      if (this.$route.name != "Home") {
        this.$router.push("/");
      }
    },
  },
  computed: {
    projectDialog() {
      return this.$store.getters["liDehnProject/getProjectDialog"];
    },
    currentRouteName() {
      return this.$route.name;
    },
    computedProjectType() {
      if (
        this.project != null &&
        this.project.type != undefined &&
        this.project.type != ""
      ) {
        return this.appConfig.types.find(
          (type) => type.identifier === this.project.type
        ).name;
      } else {
        return false;
      }
    },
    computedProjectGrid() {
      if (
        this.project.type != "00r" &&
        this.project != null &&
        this.project.grid != undefined &&
        this.project.grid != "" &&
        this.project.grid != "-"
      ) {
        // return false;
        return this.appConfig.grid.find(
          (grid) => grid.identifier === this.project.grid
        ).name;
      } else if (
        this.project.type == "00r" &&
        this.project != null &&
        this.project.grid == "-"
      ) {
        return this.appConfig.grid.find((grid) => grid.identifier === "TT")
          .name;
      } else {
        return false;
      }
    },
  },
};
</script>
<style lang="scss">
.w-100 {
  width: 100%;
}
.li-text-shadow {
  text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.9);
}
.li-dehn-logo {
  padding: 0 !important;
  width: 150px;
  .dehnLogo {
    width: 150px;
  }
}
.selection{
    letter-spacing: 0.9px!Important;
    font-size: 10px!important;
    padding: 10px!important;
    height: 25px!important;
    min-height: unset!important;
    background: transparent!important;
}
@media (max-width: 600px) {
  .li-dehn-logo {
    padding: 0 !important;
    margin: 0px!important;
    width: auto!important;
    .dehnLogo {
      width: 100px;

    }
  }
  .selection{
    letter-spacing: 0.9px!Important;
    font-size: 10px!important;
    padding: 10px!important;
    height: 25px!important;
    min-height: unset!important;
  }
}
p {
  word-break: break-word;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
